import axios from 'axios';

const api = axios.create({
    baseURL: '/api/v1',
});

export const createSession = async (data) => {
    try {
        return await api.post('/sessions/create', data);
    } catch (error) {
        console.error("Failed to create session", error);
        throw error;
    }
};

export const getSession = async (sessionId) => {
    try {
        return await api.get(`/sessions/${sessionId}`);
    } catch (error) {
        console.error("Failed to get session", error);
        throw error;
    }
};

export const createTransaction = async (data) => {
    try {
        return await api.post('/transactions/create', data);
    } catch (error) {
        console.error("Failed to create transaction", error);
        throw error;
    }
};

export const getTransactionStatus = async (transactionId) => {
    try {
        return await api.get(`/transactions/${transactionId}/status`);
    } catch (error) {
        console.error("Failed to get transaction status", error);
        throw error;
    }
};

export const createBillingRequest = async (data) => {
    try {
        return await api.post('/billing-requests/create', data);
    } catch (error) {
        console.error("Failed to create billing request", error);
        throw error;
    }
};

export const cancelTransaction = async (transactionId) => {
    try {
        await api.post(`/transactions/${transactionId}/cancel`);
    } catch (error) {
        console.error("Failed to cancel transaction", error);
        throw error;
    }
};