import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Payment from './pages/Payment';
import PaymentStatus from './components/PaymentStatus';

// Conditionally import CreatePaymentSession
const CreatePaymentSession = process.env.NODE_ENV === 'development'
    ? lazy(() => import('./pages/CreatePaymentSession'))
    : null;

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/v1/payments/:sessionId" element={<Payment />} />
                <Route path="/v1/payments/:sessionId/:transactionId?/status" element={<PaymentStatus />} />
                {process.env.NODE_ENV === 'development' && (
                    <Route
                        path="/v1/sessions/create"
                        element={
                            <Suspense fallback={<div>Loading...</div>}>
                                <CreatePaymentSession />
                            </Suspense>
                        }
                    />
                )}
            </Routes>
        </Router>
    );
};

export default App;