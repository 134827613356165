import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { CheckIcon, XMarkIcon, ClockIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { getSession, getTransactionStatus } from '../utils/api';
import confetti from 'canvas-confetti';
import { formatAmount } from '../utils/format';

const PaymentStatus = () => {
    const { sessionId, transactionId } = useParams();
    const [transactionStatus, setTransactionStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(true);
    const [returnUrl, setReturnUrl] = useState('');
    const [closeMessage, setCloseMessage] = useState('');
    const [sessionData, setSessionData] = useState(null);
    const [countdown, setCountdown] = useState(0);
    const [retryTimeout, setRetryTimeout] = useState(null);
    const [retryDelay, setRetryDelay] = useState(6000);
    const retryCount = 5;
    const [manualCheckEnabled, setManualCheckEnabled] = useState(false);
    const [isManualCheckDisabled, setIsManualCheckDisabled] = useState(false);
    const [createdAt, setCreatedAt] = useState(null);
    const [frontendDomain, setFrontendDomain] = useState('');

    // Fetch the frontend environment variable from the server and redirection logic
    useEffect(() => {
        const fetchAndRedirect = async () => {
            setLoading(true); // Set loading to true before fetching the environment variable
            const response = await fetch('/env');
            const data = await response.json();
            const mainFrontendDomain = data.REACT_APP_MAIN_FRONTEND_DOMAIN;
            setFrontendDomain(mainFrontendDomain);

            if (mainFrontendDomain) {
                if (window.location.hostname !== new URL(`https://${mainFrontendDomain}`).hostname) {
                    window.location.href = `https://${mainFrontendDomain}/v1/payments/${sessionId}/${transactionId}/status${window.location.search}`;
                } else {
                    setTimeout(async () => {
                        await fetchTransactionStatus(retryCount);
                        setLoading(false); // Set loading to false after fetching the transaction status
                    }, 3000); // Delay the transaction status fetch by 3 seconds
                }
            }
        };

        fetchAndRedirect();
    }, [sessionId, transactionId]);

    // Set the initial retryDelay and cap it at 60 seconds
    useEffect(() => {
        setRetryDelay(prevRetryDelay => {
            const doubledDelay = prevRetryDelay * 2;
            return doubledDelay > 60000 ? 60000 : doubledDelay;
        });
    }, []);

    const fetchTransactionStatus = async (retries) => {
        if (transactionId) {
            try {
                setManualCheckEnabled(false);
                setIsManualCheckDisabled(true);

                const response = await getTransactionStatus(transactionId);

                // Check if sessionData is present
                const data = response.data.sessionData || response.data;

                const { status, amount, currency, customInvoiceId, returnUrl, sessionId, createdAt } = data;

                setCreatedAt(createdAt);
                setTransactionStatus(status);
                setSessionData({
                    sessionId,
                    amount,
                    currency,
                    customInvoiceId,
                    returnUrl,
                    createdAt,
                });
                setLoading(false);

                if (response.data.sessionData?.tempTransactionId) {
                    // Notify user about the temporary transaction
                    setTransactionStatus(-1); // Use a new status code for temporary transactions
                    return;
                }

                if (status === 1) {
                    confetti();
                    if (returnUrl) {
                        setReturnUrl(returnUrl);
                        setCountdown(4);
                    }
                } else if (status === 0) {
                    if (retries > 0) {
                        setCountdown(retryDelay / 1000);
                        setRetryDelay(retryDelay * 2);
                        setRetryTimeout(setTimeout(() => {
                            fetchTransactionStatus(retries - 1);
                            setIsManualCheckDisabled(false);
                        }, retryDelay));
                    } else {
                        setManualCheckEnabled(true);
                        setIsManualCheckDisabled(false);
                    }
                } else if (status === 2) {
                    // ... (handle failed payment)
                } else if (response.status === 404 && retries === 0) {
                    fallbackToSession();
                }
            } catch (error) {
                console.error('Failed to get transaction status', error);
                if (retries === 0 && error.response?.status === 404) {
                    fallbackToSession();
                } else {
                    setRetryTimeout(setTimeout(() => fetchTransactionStatus(retries - 1), retryDelay));
                    setRetryDelay(retryDelay * 2);
                }
            }
        } else {
            setLoading(false);
        }
    };

    const fallbackToSession = async () => {
        if (sessionId) {
            try {
                const sessionResponse = await getSession(sessionId);
                if (sessionResponse.status === 200) {
                    const { sessionId, amount, currency, customInvoiceId, returnUrl, createdAt } = sessionResponse.data;
                    setTransactionStatus(0);
                    setSessionData({ sessionId, amount, currency, customInvoiceId, returnUrl });
                    setCreatedAt(createdAt);
                    setLoading(false);
                    setManualCheckEnabled(true);
                    setIsManualCheckDisabled(false);
                    setCountdown(retryDelay / 1000);
                } else {
                    console.error('Session data not found');
                    setLoading(false);
                    setRetryTimeout(setTimeout(() => fallbackToSession(), retryDelay));
                    setRetryDelay(retryDelay * 2);
                    setCountdown(retryDelay / 1000);
                }
            } catch (sessionError) {
                console.error('Failed to get session data', sessionError);
                setLoading(false);
                setRetryTimeout(setTimeout(() => fallbackToSession(), retryDelay));
                setRetryDelay(retryDelay * 2);
                setCountdown(retryDelay / 1000);
            }
        } else {
            console.error('No session data available');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (countdown > 0) {
            const intervalId = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        } else if (countdown === 0 && returnUrl) {
            window.location.href = returnUrl;
        }
    }, [countdown, returnUrl]);

    const handleManualCheck = () => {
        setManualCheckEnabled(false);
        clearTimeout(retryTimeout);
        fetchTransactionStatus(1);
    };

    const defaultButtonAction = () => {
        if (transactionStatus === 2 && sessionId) {
            window.location.href = `/v1/payments/${sessionId}`;
        } else if (returnUrl) {
            window.location.href = returnUrl;
        } else {
            window.close();
            setCloseMessage("You can safely close this page now.");
        }
    };

    const handleReload = () => {
        window.location.reload();
    };

    let icon, title, message, color, buttonText, buttonAction = defaultButtonAction;

    switch (transactionStatus) {
        case -1:
            icon = <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />;
            title = 'Transaction Pending';
            message = 'Your transaction is temporarily pending due to incomplete processing. Please reload the transaction status to check for updates later. If the status remains unchanged for several hours, please contact the merchant for assistance.';
            color = 'bg-yellow-100';
            buttonText = 'Reload Transaction Status';
            buttonAction = handleReload;
            break;
        case 0:
            icon = <ClockIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />;
            title = 'Payment is pending';
            message = 'Although your payment has been processed, the result is still pending. This process usually completes within 1-3 days. We will notify you via email once the funds are credited to our account. No further payment is required for this order.';
            color = 'bg-yellow-100';
            buttonText = 'Close';
            break;
        case 1:
            icon = <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />;
            title = 'Your payment was successful';
            message = ''; // No message required
            color = 'bg-green-100';
            buttonText = returnUrl ? `Redirecting in ${countdown} seconds...` : 'Close';
            break;
        case 2:
            icon = <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />;
            title = 'Payment failed';
            message = 'Please retry the payment or choose another payment method.';
            color = 'bg-red-100';
            buttonText = 'Try Again';
            break;
        case 3:
            icon = <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />;
            title = 'Payment refunded';
            message = 'The payment is refunded and will be credited in 2-3 days.';
            color = 'bg-red-100';
            buttonText = 'Try Again';
            break;
        case 4:
            icon = <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />;
            title = 'Chargeback received';
            message = 'A chargeback is received and this must be resolved.';
            color = 'bg-red-100';
            buttonText = 'Close';
            break;
        default:
            icon = <QuestionMarkCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />;
            title = 'Unknown status';
            message = '';
            color = 'bg-yellow-100';
            buttonText = 'Close';
            break;
    }

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                    </path>
                </svg>
            </div>
        );
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex h-full items-center justify-center p-4 text-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${color}`}>
                                        {icon}
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {title}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {message}
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            {sessionData?.customInvoiceId && (
                                                <p className="text-sm text-gray-500">
                                                    InvoiceId: {sessionData.customInvoiceId}
                                                </p>
                                            )}
                                            {transactionId && (
                                                <p className="text-sm text-gray-500">
                                                    TransactionId: {transactionId}
                                                </p>
                                            )}
                                            {sessionData?.amount && sessionData?.currency && (
                                                <p className="text-sm text-gray-500">
                                                    Amount: {formatAmount(sessionData.amount)} {sessionData.currency.toUpperCase()}
                                                </p>
                                            )}
                                            {createdAt && (
                                                <p className="text-sm text-gray-500">
                                                    Created on: {new Date(createdAt).toISOString().split('T')[0]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    {transactionStatus === 0 && !manualCheckEnabled && (
                                        <p className="text-center text-red-500">
                                            Next manual status refresh possible in {countdown} seconds
                                        </p>
                                    )}
                                    {transactionStatus === 0 && manualCheckEnabled && (
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-2"
                                            onClick={handleManualCheck}
                                            disabled={isManualCheckDisabled}
                                        >
                                            Check Status of Payment
                                        </button>
                                    )}
                                    {closeMessage ? (
                                        <p className="text-center font-bold text-green-500">{closeMessage}</p>
                                    ) : (
                                        <button
                                            type="button"
                                            className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${transactionStatus === 0 ? 'bg-gray-400' : 'bg-indigo-600'} hover:bg-${transactionStatus === 0 ? 'gray-300' : 'indigo-500'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${transactionStatus === 0 ? 'gray-400' : 'indigo-600'}`}
                                            onClick={buttonAction}
                                        >
                                            {buttonText}
                                        </button>
                                    )}
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PaymentStatus;