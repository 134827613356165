import React, { useEffect, useState, lazy, Suspense, Fragment, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSession } from '../utils/api';
import OutstandingPayment from '../components/OutstandingPayment';
import { TransitionChild, Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const PaymentForm = lazy(() => import('../components/PaymentForm'));

const Payment = () => {
    const { sessionId } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        session: null,
        loading: true,
        error: false,
        errorMessage: '',
        pendingTransaction: null
    });
    const [selectedProvider, setSelectedProvider] = useState(null);

    const fetchSession = useCallback(async () => {
        if (!sessionId) {
            setState({
                session: null,
                loading: false,
                error: true,
                errorTitle: 'Session ID Missing',
                errorMessage: 'The session ID is required to proceed with the payment. Please ensure you have a valid payment link with a session ID.',
                pendingTransaction: null
            });
            return;
        }

        try {
            const response = await getSession(sessionId);
            // Check for 403 Forbidden status
            if (response.status === 403) {
                setState({
                    session: null,
                    loading: false,
                    error: true,
                    errorTitle: 'Access Denied',
                    errorMessage: 'Your current origin is not authorized to access this payment gateway. Please contact support for assistance.',
                    pendingTransaction: null
                });
                return;
            }

            const { data } = response;
            if (data && data.pendingTransaction) {
                // Handle pending transaction
                setState({
                    session: data,
                    loading: false,
                    error: false,
                    errorMessage: '',
                    pendingTransaction: data.pendingTransaction
                });
            } else if (data && data.status === 2) {
                // Allow retry for failed transaction
                setState({
                    session: data,
                    loading: false,
                    error: false,
                    errorMessage: '',
                    pendingTransaction: null
                });
            } else if (data && data.status !== undefined) {
                // Redirect if status is available
                const transactionId = data.transactionId;
                window.location.href = `/v1/payments/${sessionId}/${transactionId}/status`;
            } else if (data && data._id) {
                // Check if the data includes expected fields
                if (data.amount && data.currency) {
                    setState({
                        session: data,
                        loading: false,
                        error: false,
                        errorMessage: '',
                        pendingTransaction: null
                    });
                } else {
                    // Handle cases where required fields are missing
                    setState({
                        session: null,
                        loading: false,
                        error: true,
                        errorTitle: 'Incomplete Session Data',
                        errorMessage: 'Some required session information is missing. If the issue persists, contact the merchant for assistance.',
                        pendingTransaction: null
                    });
                }
            } else {
                setState({
                    session: null,
                    loading: false,
                    error: true,
                    errorTitle: 'Invalid Session Data',
                    errorMessage: 'The session data is invalid. Please verify the session details and try again.',
                    pendingTransaction: null
                });
            }
        } catch (error) {
            console.error(error);
            setState({
                session: null,
                loading: false,
                error: true,
                errorTitle: 'Session Not Found',
                errorMessage: 'The session may have expired. Please create a new order or contact the merchant for further assistance.',
                pendingTransaction: null
            });
        }
    }, [sessionId]);

    useEffect(() => {
        fetchSession();
    }, [fetchSession]);

    // Check for providerId in the URL and set the selected provider
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const providerId = urlParams.get('providerId');

        if (providerId && state.session && state.session.providers) {
            const provider = state.session.providers.find(p => p._id === providerId);
            if (provider) {
                setSelectedProvider(provider); // Set the selected provider
            }
        }
    }, [state.session]);

    // Handle provider selection and redirect if necessary
    const handleProviderSelect = (provider) => {
        const targetDomain = provider.frontendDomainName || state.session.defaultFrontendDomain;

        // Always prepend https:// to the targetDomain
        const validDomain = `https://${targetDomain}`;

        // Check if the current hostname matches the target domain
        if (window.location.hostname !== new URL(validDomain).hostname) {
            // Redirect to the correct frontend domain
            window.location.href = `${validDomain}/v1/payments/${sessionId}?providerId=${provider._id}`;
        } else {
            // Set the selected provider if on the correct domain
            setSelectedProvider(provider);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    if (state.loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                    </path>
                </svg>
            </div>
        );
    }

    if (state.error) {
        return (
            <Transition show={state.error} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { /* no-op */ }}>
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    <div className="fixed inset-0 z-10 flex items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {state.errorTitle}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">{state.errorMessage}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleGoBack}
                                    >
                                        Go back to previous page
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </Dialog>
            </Transition>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            {state.pendingTransaction ? (
                <OutstandingPayment sessionId={sessionId} transaction={state.pendingTransaction} />
            ) : (
                <Suspense fallback={<div>Loading...</div>}>
                    <PaymentForm
                        sessionData={state.session}
                        isPaymentPending={!!state.pendingTransaction}
                        onProviderSelect={handleProviderSelect}
                    />
                </Suspense>
            )}
        </div>
    );
};

export default Payment;
